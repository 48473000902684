import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({

  namespace: true,
  state: {
      drawer: true,
      guid: '',
      ip: '',
      admin: {
          id: 0,
          name: '',
          email: '',
          type: 0,
          customerId: 0,
          customerIsActive: true,
          customerExpirationDate: '',
          customerInternalSales: false
      },
  },
  mutations: {
    toggleDrawer(state) {
      state.drawer = !state.drawer;
      },
      changeGuid(state, text) {
          state.guid = text;
      },
      changeAdminIp(state, text) {
          state.ip = text;
      },
      changeAdminData(state, text) {
          state.admin = text
      }
  },
  actions: {
    TOGGLE_DRAWER({ commit }) {
      commit('toggleDrawer');
    }
  },
  getters: {
    DRAWER_STATE(state) {
      return state.drawer;
    }
  }
});
