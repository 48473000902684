<template>
    <v-row v-if="isCustomerActive == false">
        <v-col cols="12">
            <v-card class="employee-list mb-1">
                <v-card-title class="pa-6 pb-3">
                    <p>Konto zablokowane!</p>
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text>Twoje konto zostało zablokowane z powodu wygaśnięcia dotychczasowej licencji. W celu ponownej aktywacji konta odnów subskrypcję w zakładce <a href="/#/subscription">Rozliczenia</a>.</v-card-text>
                <v-card-text>W przypadku pytań lub problemów z odnowieniem subskrypcji prosimy o kontakt.</v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: 'ErrorInfoSubscription',
        props: {
            isCustomerActive: Boolean
        },
    }
</script>