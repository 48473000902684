<template>
    <v-row v-if="isMainAdmin == false">
        <v-col cols="12">
            <v-card class="employee-list mb-1">
                <v-card-title class="pa-6 pb-3">
                    <p>Brak dostępu!</p>
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text>Do tych treści ma dostęp jedynie admministrator główny.</v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: 'ErrorInfoOnlyMainAdmin',
        props: {
            isMainAdmin: Boolean
        },
    }
</script>