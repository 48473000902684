<template>
    <div style="width: 1800px">
        <v-app>
            <v-container fluid>
                <div class="tables-basic">
                    <v-row class="d-flex justify-center">
                        <v-col cols="12">
                            <v-col cols="7">
                                <v-card class="employee-list mb-1">
                                    <v-card-title class="pa-10 pb-10">
                                        <p>Sygnali.pl - Rejestr zgłoszeń sygnalistów</p>
                                        <v-spacer></v-spacer>
                                        <p>{{new Date().toLocaleDateString("pl-PL")}}</p>
                                    </v-card-title>
                                    <v-card-text>Lista wybranych zgłoszeń: </v-card-text>
                                    <v-data-table :headers="newSignals.headers"
                                                  :items="newSignals.signals"
                                                  item-key="id"
                                                  :hide-default-footer="true">
                                    </v-data-table>
                                    <br /><br /><br />
                                    <v-card-text>© Copyright 2021, MWC Sp. z o.o. Kowalewicka 12, 60-002 Poznań</v-card-text>
                                </v-card>
                            </v-col>
                        </v-col>
                    </v-row>
                </div>
            </v-container>
        </v-app>
    </div>
</template>

<script>
    import Service from '@/services/Service'
export default {
  name: 'ReportPrint',
  data() {
    return {
      newSignals: [],
      signals: [],
      admin: ''
    }
  },
  async mounted() {
      try {
          
          this.newSignals = {
              selected: [],
              search: '',
              headers: [
                  {
                      text: 'Identyfikator',
                      align: 'start',
                      sortable: false,
                      value: 'identifier',
                  },
                  { text: 'Data wpływu', value: 'datetime', sortable: false },
                  { text: 'Data zakończenia', value: 'archiveTime' },
                  { text: 'Etykieta', value: 'title', sortable: false },
                  { text: 'Status', value: 'statusForReport', sortable: false },
                  { text: 'Kategoria', value: 'category' },

              ],
              signals: JSON.parse(localStorage['reportsToPrintForAdmin']),
              
          },
              this.admin = (await Service.getAdminName()).data.loginUser,
             this.$nextTick(function () {
                 window.print();
              })
      } catch (e) {
          alert(e.response.status + " - " + e.response.data.toString())
          if (e.response.status == 401)
              this.$router.push({ name: 'Login' })
      }
  },
  methods: {
    getAllReports() {

    }
  }
}

</script>

<style src="./Basic.scss" lang="scss">
</style>
