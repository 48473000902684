<template>
  <v-app>
    <v-container fluid id="login">
      <v-row no-gutters>
        <v-col cols="3" class="main-part d-none d-md-none d-lg-flex">
            <div class="d-flex">
                <v-img src="@/assets/sygnali_logo_450.png" contain style="width:60%"></v-img>
                <span style="color:white">PLATFORMA OCHRONY SYGNALISTÓW</span><br /><br />
                <v-card-text><p style="font-size: 1.5em">SKRZYNKA ODBIORCZA SYGNALISTY</p></v-card-text>
            </div>
        </v-col>
        <v-col cols="12" lg="9" class="login-part d-flex align-center justify-center">
          <v-row no-gutters class="align-start">
            <v-col cols="12" class="login-part d-flex align-center justify-center flex-column">
              <div class="login-wrapper pt-md-4 pt-0">
                <v-tabs grow>
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab :href="`#tab-login`">
                    LOGOWANIE
                  </v-tab>
                  <v-tab-item :value="'tab-login'" >
                    <v-form>
                      <v-container>
                        <v-row class="flex-column">
                          <v-col cols="12" class="d-flex align-center my-8">
                          </v-col>
                          <v-form>
                            <v-col>
                              <v-text-field
                                  v-model="identifier"
                                  value=""
                                  :rules="idRules"
                                  label="Identyfikator"
                                autocomplete ="off"
                                  required
                              ></v-text-field>
                              <v-text-field
                                  v-model="password"
                                  :rules="passRules"
                                  type="password"
                                  label="Hasło"
                                  hint="Minimum 4 zanki"
                                  required
                              ></v-text-field>

                            </v-col>
                            <v-col class="d-flex justify-space-between">
                              <v-btn
                                  class="text-capitalize"
                                  large
                                     autocomplete ="off"
                                  :disabled="password.length === 0 || identifier.length === 0"
                                  color="primary"
                                  @click="login"
                              >
                                Zaloguj</v-btn>
                            </v-col>
                          </v-form>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-tab-item>
                </v-tabs>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
    import Service from '@/services/Service'
  export default {
    name: 'Login',
    data() {
      return {
          identifier: '',
        idRules: [
          v => !!v || 'Identyfikator jest wymagany',
        ],
        password: '',
        passRules: [
          v => !!v || 'Hasło jest wymagane',
          v => v.length >= 4 || 'Minimum 4 zanki'
        ]
      }
    },
    created(){
        document.title = "Sygnali.pl";
    },
    methods: {
        async login() {
            try {
                const user = {
                    identifier: this.identifier,
                    pass: this.password
                }
                const result = (await Service.loginToInbox(user)).data;
                if (Object.keys(result).length > 0) {
                    this.$store.commit('changeGuid', result);
                    this.$router.push({ name: 'Inbox', params: { identifier: this.identifier, pass: this.password } });
                }
            } catch (e) {
                alert(e.response.status + " - " + e.response.data.toString());
                return;
            }
      }
    }
  }

</script>

<style src="../Login/Login.scss" lang="scss"/>
