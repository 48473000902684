<template>
  <v-app>
    <v-container fluid id="login">
      <v-row no-gutters>
        <v-col cols="3" class="main-part d-none d-md-none d-lg-flex">
            <div class="d-flex">
                <v-img src="@/assets/sygnali_logo_450.png" contain style="width:60%"></v-img>
                <span style="color:white">PLATFORMA OCHRONY SYGNALISTÓW</span>
            </div>
        </v-col>
        <v-col cols="12" lg="9" class="login-part d-flex align-center justify-center">
          <v-row no-gutters class="align-start">
            <v-col cols="12" class="login-part d-flex align-center justify-center flex-column">
              <div class="login-wrapper pt-md-4 pt-0">
                <v-tabs fixed-tabs>
                   <v-tab :href="`#tab-newUser`">
                    RESETUJ HASŁO
                  </v-tab> 
                  <v-tab-item :value="'tab-newUser'">
                      <v-form>
                          <v-container>
                              <v-row class="flex-column">

                                  <v-col>
                                      <p class="login-slogan display-2 text-center font-weight-medium mt-10">Witaj!</p>
                                      <p class="login-slogan display-1 text-center font-weight-medium mb-10">Podaj adres email konta</p>
                                  </v-col>

                                  <v-form>
                                      <v-col>
                                          <v-text-field v-model="createEmail"
                                                        :rules="emailRules"
                                                        label="Adres Email"
                                                        required></v-text-field>
                                      </v-col>
                                      <v-col class="d-flex justify-space-between">
                                          <v-btn large
                                                 block
                                                 :disabled="createEmail.length === 0"
                                                 color="primary"
                                                 @click="reset()">
                                              Potwierdź
                                          </v-btn>
                                      </v-col>
                                  </v-form>
                              </v-row>
                          </v-container>
                      </v-form>
                  </v-tab-item>

                </v-tabs>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
    import Service from '@/services/Service'
  export default {
    name: 'ResetPassword',
    data() {
      return {
        email: '',
        emailRules: [
          v => !!v || 'E-mail jest wymagany',
          v => /.+@.+/.test(v) || 'Niepoprawny E-mail',
        ],
        createFullName: '',
        createEmail: '',
        createPassword: '',
        password: '',
        passRules: [
          v => !!v || 'Hasło jest wymagane',
          v => v.length >= 6 || 'Minimum 6 znaków'
        ]
      }
    },
    created(){
        document.title = "Sygnali.pl";
    },
    methods: {
    validateEmail(email) {
        // eslint-disable-next-line
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    },
    async reset() {
        try {
            if (!this.validateEmail(this.createEmail)) {
                alert("Wprowadź poprawny email")
                return;
            }

        const user = {
            email: this.createEmail,
        }

        const result = (await Service.passwordReset(user));

            if (result.status == 200) {
                alert("Na wskazany adres email wysłano link do resetowania hasła")
                return;
        }
    } catch (e) {
            alert(e.response.status + " - " + e.response.data.toString());
            return;
    }
}
},
  }

</script>

<style src="./Login.scss" lang="scss"/>
