<template>
    <v-app>
        <v-container fluid id="login">
            <v-row no-gutters>
                <v-col cols="3" class="main-part d-none d-md-none d-lg-flex">
                    <div class="d-flex">
                        <v-img src="@/assets/sygnali_logo_450.png" contain style="width:60%"></v-img>
                        <span style="color:white">PLATFORMA OCHRONY SYGNALISTÓW</span>
                    </div>
                </v-col>
                <v-col cols="12" lg="9" class="login-part d-flex align-center justify-center">
                    <v-row no-gutters class="align-start">
                        <v-col cols="12" class="login-part d-flex align-center justify-center flex-column">
                            <div class="login-wrapper pt-md-4 pt-0">
                                <v-tabs grow>
                                    <v-tabs-slider></v-tabs-slider>
                                    <v-tab :href="`#tab-login`">
                                        RESETUJ HASŁO - KROK 2
                                    </v-tab>
                                    <v-tab-item :value="'tab-login'">
                                        <v-form>
                                            <v-container>
                                                <v-row class="flex-column">
                                                    <v-col cols="12" class="d-flex align-center my-8">
                                                    </v-col>
                                                    <v-form>
                                                        <v-col>
                                                            <v-text-field v-bind:value="email"
                                                                          label="Adres Email"
                                                                          required
                                                                          disabled></v-text-field>
                                                            <v-text-field v-model="password"
                                                                          :rules="passRules"
                                                                          type="password"
                                                                          label="Ustaw nowe hasło"
                                                                          hint="Minimum 6 znaków"
                                                                          required></v-text-field>
                                                        </v-col>
                                                        <v-col class="d-flex justify-space-between">
                                                            <v-btn class="text-capitalize"
                                                                   large
                                                                   :disabled="password.length < 6 || email.length === 0"
                                                                   color="primary"
                                                                   @click="resetPass">
                                                                Potwierdź
                                                            </v-btn>
                                                        </v-col>
                                                    </v-form>
                                                </v-row>
                                            </v-container>
                                        </v-form>
                                    </v-tab-item>
                                </v-tabs>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
    import Service from '@/services/Service'
  export default {
    name: 'Reset',
    data() {
      return {
        email: '',
        emailRules: [
          v => !!v || 'E-mail jest wymagany',
          v => /.+@.+/.test(v) || 'Niepoprawny E-mail',
        ],
          createPassword: '',
          password: '',
        passRules: [
          v => !!v || 'Hasło jest wymagane',
          v => v.length >= 6 || 'Minimum 6 znaków'
        ]
      }
    },
    created(){
        document.title = "Sygnali.pl";
    },
    async mounted() {
        try {
            this.email = (await Service.getEmailFromResetLink(this.$route.params.guidreset)).data.email
        } catch (e) {
            alert(e.response.status + " - " + e.response.data.toString())
            this.$router.push({ name: 'Error2' });
        }
    },
    methods: {
        async resetPass() {
            try {
                const user = {
                    email: this.email,
                    password: this.password
                }

                const result = (await Service.resetPassForAdmin(user));
                if (result.status == 200) {
                    alert("Dziękujemy. Nowe hasło zostało ustawione. Teraz możesz się zalogować.");
                    this.$router.push('/login');
                }
            } catch (e) {
                alert(e.response.status + " - " + e.response.data.toString());
                return;
                //this.$router.push({ name: 'Error' });
            }
      }
    },
  }

</script>

<style src="./Login.scss" lang="scss" />
