<template>
    <v-container fluid>
        <div class="tables-basic">
            <h1 class="page-title mt-10 mb-6">Podsumowanie płatności</h1>

            <div>
                <v-col cols="12">
                    <v-card>
                        <v-card-title class="pa-6 pb-3">
                            <p>{{tokenInfo.statusName}}</p>
                            <v-spacer></v-spacer>
                            <v-spacer></v-spacer>
                        </v-card-title>
                        <v-card-text class="pa-3 pb-0">Dotyczy: Zakup pakietu Sygnali <b>{{tokenInfo.packageName}}</b> na okres <b>{{tokenInfo.paymentModelName}}</b>.</v-card-text>
                        <v-card-text class="pa-3 pb-0"><b>{{tokenInfo.statusDescription}}</b>
                        <v-progress-circular v-if="showLoader" style="margin-left: 20px;" :width="4" color="green" indeterminate></v-progress-circular>
                        </v-card-text>
                        <br />
                        <v-card-text class="pa-3 pb-0" v-if="tokenInfo.status == 4">W celu wygenerowania faktury kliknij w link: <a href="#" @click="generateInvoice()">Generuj fakturę</a>. Faktrurę można w każdej chwili pobrać w zakładce <a href="/#/orders">Zamówienia</a>.</v-card-text>
                        <v-card-text class="pa-3 pb-0" v-if="tokenInfo.status == 1 || tokenInfo.status == 3">W celu sprawdzenia swojej płatności w systemie Przelewy24, wejdż w link: <a href="#" @click="payForTransaction()">Zamówienie</a> lub sprawdź otrzymany email od serwisu Przelewy24. W przypadku błędu płatności w serwisie Przelewy24 - spróbuj wykonać zakup ponownie.</v-card-text>
                        <v-card-text class="pa-3 pb-3">W przypadku pytań lub problemów z płatnością prosimy o kontakt.</v-card-text>
                    </v-card>
                </v-col>
            </div>
        </div>
    </v-container>
</template>

<script>
import Service from '@/services/Service'

export default {
    name: 'TransactionInformation',
    data() {
        return {
            tokenInfo: {},
            showLoader: false,
        }
    },
    async mounted() {
        try {
            this.tokenInfo = (await Service.getOrderInfo(this.$route.params.token)).data;         

            if (this.tokenInfo.status == 4) {
                await this.updateTk();
                await this.generateInvoice();
            }

            if (this.tokenInfo.status == 3) {
                this.showLoader = true;
                await this.getInfoCyclic();
            }

        } catch (e) {
            alert(e.response.status + " - " + e.response.data.toString())
            if (e.response.status == 401)
                this.$router.push({ name: 'Login' })
        }
  },
  methods: {
      async generateInvoice() {
          try {
              var result = (await Service.getInvoiceOrderForClient(this.tokenInfo.id)).data;
              if (result.length > 0) {
                  window.open(result);
              }

          } catch (e) {
              alert(e.response.status + " - " + e.response.data.toString())
              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
      },
      payForTransaction() {
          window.open(this.tokenInfo.paymentUrl);
      },
      async updateTk() {
          try {
              var data = {
                  transactionTk: this.$route.params.token,
                  uid: this.tokenInfo.uid
              }

              var result = (await Service.updateTkAfterTransaction(data)).data;
              this.$store.commit('changeAdminData', result);

          } catch (e) {
              alert(e.response.status + " - " + e.response.data.toString())
              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
      },
      async getInfoCyclic() {
          try {
              const timer = ms => new Promise(res => setTimeout(res, ms))

              for (var i = 0; i < 6; i++) {
                  this.tokenInfo = (await Service.getOrderInfo(this.$route.params.token)).data;

                  if (this.tokenInfo.status == 4) {
                      await this.updateTk();
                      await this.generateInvoice();
                      break;
                  }
                  else {
                      if (i == 5) {
                          this.tokenInfo = (await Service.setNoPaymentStatus(this.$route.params.token)).data;
                          this.showLoader = false;
                          break;
                      }
                  }
     
                  await timer(10000); // then the created Promise can be awaited
              }

          } catch (e) {
              alert(e.response.status + " - " + e.response.data.toString())
              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
      }
      
  }
}

</script>

<style scope>
   

</style>
