<template>
    <v-container fluid>
        <div class="tables-basic">
            <h1 class="page-title mt-10 mb-6">Zamówienia</h1>

            <ErrorMainAdmin :isMainAdmin="isMainAdmin()" />
            <ErrorInternalSales v-if="isInternalSales() == true" :isInternalSales="isInternalSales()" />

            <v-row v-if="isMainAdmin() && isInternalSales() !== true">
                <v-col cols="12">
                    <v-card class="employee-list mb-1">
                        <v-card-title class="pa-6 pb-3">
                            <p>Historia zamówień</p>
                            <v-spacer></v-spacer>
                            <v-text-field v-model="orders.search"
                                          append-icon="mdi-magnify"
                                          label="Szukaj"
                                          clearable
                                          single-line
                                          hide-details></v-text-field>
                        </v-card-title>
                        <v-data-table v-model="orders.selected"
                                      :headers="orders.headers"
                                      :items="orders.ord"
                                      :search="orders.search"
                                      item-key="identifier"
                                      :loading="loading"
                                      loading-text="Ładowanie... Proszę czekać"
                                      :items-per-page.sync="itemsPerPage"
                                      :hide-default-footer="false"
                                      :footer-props="{'items-per-page-options': [10, 20, 30, 40, -1], 'items-per-page-text': 'Załaduj wiersze' }">

                            <template v-slot:[`item.actions`]="{ item }">
                                <v-tooltip top v-if="item.paymentStatusId == 4">
                                    <template v-slot:activator="{ on }">
                                        <v-btn text @click="getInvoiceOrder(item)" v-on="on">
                                            <v-icon color="red">mdi-file-download-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>POBIERZ FAKTURĘ</span>
                                </v-tooltip>
                                <v-tooltip top v-if="item.paymentStatusId != 4 && item.paymentStatusId != 1">
                                    <template v-slot:activator="{ on }">
                                        <v-btn text @click="goToPaymentSite(item)" v-on="on">
                                            <v-icon color="green">mdi-bank-transfer-in</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>OPŁAĆ ZAMÓWIENIE</span>
                                </v-tooltip>
                            </template>

                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>

        </div>
    </v-container>
</template>

<script>
    import Service from '@/services/Service'
    import ErrorMainAdmin from '@/components/Modals/ErrorInfoOnlyMainAdmin.vue'
    import ErrorInternalSales from '@/components/Modals/ErrorInternalSales.vue'
    export default {
        name: 'Orders',
        components: {
            ErrorMainAdmin,
            ErrorInternalSales
        },
        data() {
            return {
                orders: [],
                itemsPerPage: 10,
                loading: true
            }
        },
        async mounted() {
            try {

                if (this.isMainAdmin() && this.isInternalSales() !== true) {
                    await this.loadRecords();
                }

            } catch (e) {
                if (e.response.status == 401)
                    this.$router.push({ name: 'Login' })
            }
        },
        watch: {
            itemsPerPage: {
                handler() {
                    if (this.itemsPerPage > 20 || this.itemsPerPage == -1) {
                        this.loadRecords();
                    }
                },
                deep: true,
            },
        },
        methods: {
            goToPaymentSite(item) {
                window.open(item.przelewy24Token);
            },
            isMainAdmin() {
                return this.$store.state.admin.type == 1;
            },
            isInternalSales() {
                return this.$store.state.admin.customerInternalSales;
            },
            async getInvoiceOrder(item) {
                try {
                    var result = (await Service.getInvoiceOrderForClient(item.id)).data;
                    if (result.length > 0) {
                        window.open(result);
                    }
                } catch (e) {
                    alert(e.response.status + " - " + e.response.data.toString())
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            async loadRecords() {
                try {
                    this.loading = true;
                    var result = (await Service.getOrderList()).data;

                    this.orders = {
                        selected: [],
                        search: '',
                        headers: [
                            {
                                text: 'Id',
                                align: 'start',
                                sortable: true,
                                value: 'id',
                            },
                            { text: 'Wersja', value: 'packageName' },
                            { text: 'Okres', value: 'paymentModelName' },
                            { text: 'Cena netto [zł]', value: 'priceNetto' },
                            { text: 'Cena brutto [zł]', value: 'priceBrutto' },
                            { text: 'Data zakupu', value: 'purchaseTime' },
                            { text: 'status', value: 'paymentStatusName' },
                            { text: '', value: 'actions' },
                        ],
                        ord: result,
                    },
                        this.loading = false
                } catch (e) {
                    console.log(e)
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            }
        }
    }

</script>

<style src="../Reports/Basic.scss" lang="scss">
</style>
